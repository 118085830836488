import React, { Component } from 'react';
import { qaAPIEndpoint, awsImageAPIEndpoint, commentAPIEndpoint, categoryAPIEndpoint } from '../../api';
import { Loading } from '../Utility/Loading';
import { QAheader } from '../Utility/QAheader';

import currentDateTime from '../Utility/fncustom';

export class New extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryId: 1,
            status: 'New',
            address: '',
            materials: [],
            materialId: 0,          //  0 - Tiling
            checkpoints: [],
            checkpointIds: [],
            material: '',
            grout: '',
            sealant: '',
            images: [],
            comment: '',
            loading: false,
            qaNo: null
        }
    }

    componentDidMount() {
        this.setState({ loading: true });

        qaAPIEndpoint("qas")
            .getNewQANo()
            .then(res => {
                this.setState({ qaNo: res.data });
            });

        categoryAPIEndpoint('category')
            .getMaterials(this.state.categoryId)
            .then(materialRes => {
                var materialId = materialRes.data.length == 0 ? this.state.categoryId == 4 ? 0 : -1 : materialRes.data[0].id;
                categoryAPIEndpoint('category')
                    .getCheckpoint(materialId)
                    .then(checkpointRes => {
                        this.setState({
                            materials: materialRes.data,
                            materialId: materialId,
                            checkpoints: checkpointRes.data,
                            loading: false
                        });
                    });
            });
    }

    upload = () => {
        document.getElementById('imageUpload').click();
    }
    handleAddImage = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const images = this.state.images.slice();
            for (let i = 0; i < e.target.files.length; i++) {
                images.push(e.target.files[i]);
            }

            this.setState({ images: images });
        }
    }
    cancelImage = i => {
        var imgs = this.state.images.slice();
        imgs.splice(i, 1);
        this.setState({ images: imgs });
    }

    handleCategory = ct => {
        categoryAPIEndpoint('category')
            .getMaterials(ct)
            .then(materialRes => {
                var materialId = materialRes.data.length == 0 ? (ct == 4 ? 0 : -1) : materialRes.data[0].id;
                categoryAPIEndpoint('category')
                    .getCheckpoint(materialId)
                    .then(checkpointRes => {
                        this.setState({
                            categoryId: ct,
                            materials: materialRes.data,
                            materialId: materialId,
                            checkpoints: checkpointRes.data,
                            checkpointIds: []
                        });
                    });
            });
    }

    handleCheckpoint = e => {
        const checkpointIds = this.state.checkpointIds.slice();
        const i = checkpointIds.indexOf(Number(e.target.value));

        if (i > -1) {
            checkpointIds.splice(i, 1);
            this.setState({ checkpointIds: checkpointIds });
        } else {
            this.setState({ checkpointIds: checkpointIds.concat(Number(e.target.value)) });
        }
    }

    handleMaterial = e => {
        this.setState({ materialId: e.target.value }, () => {
            categoryAPIEndpoint('category')
                .getCheckpoint(e.target.value)
                .then(checkpointRes => {
                    this.setState({
                        checkpoints: checkpointRes.data
                    });
                });
        });

    }

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    }

    /* Result process */
    handleClear = () => {
        this.setState({
            address: '',
            checkpointIds: [],
            grout: '',
            sealant: '',
            images: [],
            comment: ''
        });
    }

    handleSave = saveType => {
        this.setState({ loading: true });
        /* 1. image upload */
        var formData = new FormData();
        var images = this.state.images;

        formData.enctype = "multipart/form-data";
        for (let i = 0; i < images.length; i++) {
            formData.append("files", images[i], images[i].name);
        }

        /* 2. save data */
        const user = JSON.parse(localStorage.userAuthenticated);
        const dataQA = {
            userid: user.id,
            categoryid: this.state.categoryId,
            status: saveType == "save" ? "New" : "Submitted",
            address: this.state.address,
            checkpointIds: this.state.checkpointIds.join(),
            materialid: this.state.materialId,
            material: this.state.material,
            grout: this.state.grout,
            sealant: this.state.sealant,
        }

        qaAPIEndpoint("qas")
            .post(dataQA)
            .then(QAres => {
                this.formId = QAres.data.id;
                awsImageAPIEndpoint("aws")
                    .uploadImages(QAres.data.id, formData)
                    .then(res => {
                        if (this.state.comment.trim().length > 0) {
                            const dataComment = {
                                qaid: QAres.data.id,
                                userid: user.id,
                                cmt: this.state.comment,
                                commentTime: currentDateTime()
                            };

                            commentAPIEndpoint('comments')
                                .save(dataComment)
                                .then(res => {
                                    this.setState({ loading: false });
                                    this.props.history.push("/my-qas");
                                })
                                .catch(commentErr => console.log(commentErr))
                        } else {
                            this.props.history.push("/my-qas");
                        }
                        document.location.href = `/detail-qa-form/${QAres.data.id}`
                    })
                    .catch(uploadErr => console.log(uploadErr));
            })
            .catch(err => console.log(err))        
    }   /* Result process */

    render() {
        return (
            <>
                {
                    this.state.loading ? <Loading /> : ""
                }

                <div className="qa-content">
                    <QAheader
                        qaNo={this.state.qaNo}
                        status={this.state.status}
                        categoryId={this.state.categoryId}
                        handleCategory={this.handleCategory}
                    />

                    <div className="mt-5">
                        <div className="row mb-3">
                            <div className="col-md-8 d-flex">
                                <span className="h5 text-nowrap">Property Address:</span>
                                <input
                                    id="address"
                                    className="search form-control form-control-sm ms-2"
                                    placeholder="109 Queen Street, Auckland, New Zealand"
                                    value={this.state.address}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-7 checkpoints">
                                <span className="h6">Check Point</span>
                                {
                                    this.state.checkpoints.map((checkpoint, index) => {
                                        var checked = this.state.checkpointIds.includes(checkpoint.id);
                                        return (
                                            <div key={index} className="check-point d-flex align-items-start gap-4">
                                                <div className="check-point-done d-flex align-items-center gap-2 mb-3">
                                                    <input
                                                        type="checkbox"
                                                        value={checkpoint.id}
                                                        onChange={this.handleCheckpoint}
                                                        checked={checked}
                                                    />
                                                    <span>Done</span>
                                                </div>
                                                <div className="check-point-description">
                                                    <p>{checkpoint.name}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="col-md-5">
                                {
                                    (this.state.categoryId == 1 || this.state.categoryId == 2 || this.state.categoryId == 3) &&
                                    (
                                        <>
                                            <span>Material Used:</span>
                                            <select className="form-select" onChange={this.handleMaterial} value={this.state.materialId}>
                                                {this.state.materials.length == 0 && (<option value="-1">No material</option>)}
                                                {
                                                    this.state.materials.map((material, index) => {
                                                        return (
                                                            <option key={index} value={material.id}>{material.name}</option>
                                                        )
                                                    })
                                                }

                                            </select>
                                        </>
                                    )
                                }{
                                    this.state.categoryId == 4 &&
                                    (
                                        <div className="options d-flex flex-column gap-3 p-3 w-75">
                                            <div className="material d-flex">
                                                <span className="text-nowrap">Material Used:</span>
                                                <input type="text" id="material" className="form-control form-control-sm ms-2" placeholder="Rubber" value={this.state.material} onChange={this.handleChange} />
                                            </div>

                                            <div className="grout d-flex">
                                                <span className="text-nowrap">Grout Color:</span>
                                                <input type="text" id="grout" className="form-control form-control-sm ms-2" placeholder="Red" value={this.state.grout} onChange={this.handleChange} />
                                            </div>

                                            <div className="sealant d-flex">
                                                <span className="text-nowrap">Sealant Color:</span>
                                                <input type="text" id="sealant" className="form-control form-control-sm ms-2" placeholder="Blue" value={this.state.sealant} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                        <hr />

                        <div className="row">
                            <div className="col-md-7">
                                <div className="d-flex">
                                    <span className="h5">Upload Pictures: </span>
                                    <span className="ms-3">Support jpg, gif, bmp, png format size limit: 2GB</span>
                                </div>
                                <div className="d-flex flex-wrap gap-4 mt-3 overflow-auto" style={{ 'height': '200px' }}>
                                    {
                                        this.state.images.map((image, index) => {
                                            return (
                                                <div key={index} className="qa-img-thumbnail m-1">
                                                    <img
                                                        src={URL.createObjectURL(image)}
                                                        alt="Thumb"
                                                    />
                                                    <div className="qa-img-cancel">
                                                        <img src={process.env.PUBLIC_URL + "images/icons/close.png"} onClick={()=>this.cancelImage(index)} />
                                                    </div>
                                                </div>
                                            )

                                        })
                                    }

                                </div>
                                <input
                                    className="btn"
                                    id='imageUpload'
                                    name="imageUpload"
                                    type="file"
                                    accept="image/*"
                                    onChange={this.handleAddImage}
                                    hidden multiple
                                />
                                <button className="btn btn-secondary mt-3" onClick={this.upload}>Upload</button>
                            </div>
                            <div className="col-md-5 d-flex flex-column">
                                <span className="h5">Comments</span>
                                <textarea
                                    id="comment"
                                    className="form-control"
                                    rows="10"
                                    onChange={this.handleChange}
                                    value={this.state.comment}>
                                </textarea>
                            </div>
                        </div>
                        <hr />
                        <div className="row my-5">
                            <div className="col-md-12 text-end mt-5 result-btns">
                                <button className="btn btn-secondary mx-2" onClick={this.handleGoBack}>Cancel</button>
                                <button className="btn btn-secondary mx-2" onClick={() => this.handleSave("save")}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
