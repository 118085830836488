import React, { Component } from 'react';

export class NewMaterial extends Component {

    render() {
        return (
            <div className="ct-new">
                <div className="card">
                    <div className="card-header">New</div>
                    <div className="card-body">
                        <p>Material name</p>
                        <input id="new-material-name" className="form-control my-3" />
                        <div className="d-flex gap-4">
                            <button className="btn btn-secondary" onClick={this.props.saveNewMaterial}>Save</button>
                            <button className="btn btn-secondary" onClick={this.props.cancelNewMaterial}>Cancel</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}