var user = {};
try {
    user = JSON.parse(localStorage.userAuthenticated)
} catch (err) {
    console.log(err);
}

export const USERINFO = {
    ID: user.id,
    NAME: user.name,
    ROLE: user.role,
}