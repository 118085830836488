import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { USERINFO } from './Auth/userinfo';


const menu = [
    {
        name: "My Detail",
        path: "/user"
    },
    {
        name: "My QA Form",
        path: "/my-qas",
        path1: "/detail-qa-form"
    },
    {
        name: "Create New QA Form",
        path: "/new-qa-form",
    },
    {
        name: "Category",
        path: "/category"
    }
];

export class SideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: 1
        }
    }
    componentDidMount() {
    }

    handleMenu = i => {
        this.setState({ activeItem: i });

    }
    render() {
        var isOpen = this.props.isOpenSidemenu ? "" : "open";
        const path = window.location.pathname;
        return (
            <>
                <div className={`sidemenu ${isOpen}` } >
                    <a className="avatar">
                        <img src={process.env.PUBLIC_URL + "images/avatars/avatar-2.jpg"} />
                        <p className="fw-bold">{USERINFO.NAME}</p>
                    </a>

                    <ul className="navbar-nav flex-grow mt-5">
                        {
                            menu.map((m, i) => {
                                var active = path.includes(m.path) || path.includes(m.path1) ? "active" : "";
                                return (
                                    <NavItem key={i} className={`px-2 ${active}`} onClick={() => this.handleMenu(i)}>
                                        <NavLink tag={Link} className="text-dark" to={m.path}>{m.name}</NavLink>
                                    </NavItem>
                                )
                            })
                        }
                    </ul>
                </div>
            </>
        );
    }
}
