import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { qaAPIEndpoint } from '../../api';
import { USERINFO } from '../Auth/userinfo';
import { QApagination } from '../Utility/QApagination';

const Category = ["Foundation", "Roof", "WetArea", "Tiling"];
export class MyQAs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            qas: [],
            status: '',
            address: '',
            name: '',
            type: 0,            //  categoryId
            loading: true,
            totalRows: 0,
            pageSize: 20,
            currentPageNo: 1
        };
    }

    componentDidMount() {
        this.populateQAsData();
    }

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    }

    handleSearch = () => {
        this.setState({ loading: true });
        this.populateQAsDataFiltered();
    }

    pickPageSize = e => {
        this.setState({
            pageSize: e.target.value,
            currentPageNo: 1
        }, () => {
            this.populateQAsDataFiltered();
        });
    }

    pickPageNo = page => {
        this.setState({ currentPageNo: page }, () => {
            this.populateQAsDataFiltered();
        });
    }

    pagePrev = () => {
        if (this.state.currentPageNo > 1) {
            this.setState({ currentPageNo: this.state.currentPageNo - 1 }, () => {
                this.populateQAsDataFiltered();
            });
        }
    }
    pageNext = () => {
        if (this.state.currentPageNo < Math.ceil(this.state.totalRows / this.state.pageSize)) {
            this.setState({ currentPageNo: this.state.currentPageNo + 1 }, () => {
                this.populateQAsDataFiltered();
            });
        }
    }

    static renderMyQAsTable(QAs) {
        return (
            <table id="qatb" className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>QA ID</th>
                        <th>Address</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Operation</th>
                    </tr>
                </thead>
                <tbody>
                    {QAs.map(qa =>
                        <tr key={qa.id}>
                            <td>WL-QA-{qa.id}</td>
                            <td>{qa.address.slice(0,25)}</td>
                            <td>{qa.user.name}</td>
                            <td>{Category[qa.categoryId - 1]}</td>
                            <td>{qa.status}</td>
                            <td><Link className="to-qa-detail" to={`/detail-qa-form/${qa.id}`}>Details</Link></td>
                        </tr>
                    )}
                </tbody>
            </table>

        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : MyQAs.renderMyQAsTable(this.state.qas);

        return (
            <>
                <h1 id="tabelLabel" >My QA Form</h1>
                <div className="qas-table overflow-auto">
                    <div className="search d-flex gap-3">
                        <input
                            id="status"
                            className="form-control"
                            placeholder="Status"
                            onChange={this.handleChange}
                        />
                        <input
                            id="address"
                            className="form-control"
                            placeholder="Address"
                            onChange={this.handleChange}
                        />
                        <input
                            id="name"
                            className="form-control"
                            placeholder="Name"
                            onChange={this.handleChange}
                        />
                        <select
                            id="type"
                            className="form-select"
                            onChange={this.handleChange}
                        >
                            <option value="-1"></option>
                            {
                                Category.map((c, i) => {
                                    return (
                                        <option key={i} value={i+1}>{c}</option>
                                    )
                                })
                            }
                        </select>
                        <button
                            className="btn btn-secondary"
                            onClick={this.handleSearch}
                        >
                            Search
                        </button>
                    </div>
                    {contents}
                    <QApagination
                        pageSize={this.state.pageSize}
                        totalRows={this.state.totalRows}
                        currentPageNo={this.state.currentPageNo}
                        pickPageNo={this.pickPageNo}
                        pickPageSize={this.pickPageSize}
                        pagePrev={this.pagePrev}
                        pageNext={this.pageNext}
                    />
                </div>
            </>
        );
    }

    populateQAsData() {
        if (USERINFO.ROLE == "Employee") {
            qaAPIEndpoint('qas')
                .getRowCountOfQAsByEmployee(USERINFO.ID)
                .then(res => {
                    this.setState({ totalRows: res.data });
                });

            qaAPIEndpoint("qas")
                .getQAsByEmployee(USERINFO.ID, '', '', '', -1, this.state.currentPageNo, this.state.pageSize)
                .then(
                    res => {
                        this.setState({ qas: res.data, loading: false });
                    }
                );
        } else if (USERINFO.ROLE == "Manager") {
            qaAPIEndpoint('qas')
                .getRowCountOfQAsByManager(USERINFO.ID)
                .then(res => {
                    this.setState({ totalRows: res.data });
                });

            qaAPIEndpoint("qas")
                .getQAsByManager(USERINFO.ID, '', '', '', -1, this.state.currentPageNo, this.state.pageSize)
                .then(
                    res => {
                        this.setState({ qas: res.data, loading: false });
                    }
                );
        } else {    //  Admin
            qaAPIEndpoint('qas')
                .getRowCountOfQAsByAdmin()
                .then(res => {
                    this.setState({ totalRows: res.data });
                });

            qaAPIEndpoint("qas")
                .getQAsByAdmin('', '', '', -1, this.state.currentPageNo, this.state.pageSize)
                .then(
                    res => {
                        this.setState({ qas: res.data, loading: false });
                    }
                );
        }
    }

    populateQAsDataFiltered() {
        if (USERINFO.ROLE == "Employee") {
            qaAPIEndpoint("qas")
                .getQAsByEmployee(USERINFO.ID, this.state.status, this.state.address, this.state.name, this.state.type, this.state.currentPageNo, this.state.pageSize)
                .then(
                    res => {
                        this.setState({ qas: res.data, loading: false });
                    }
                );
        } else if (USERINFO.ROLE == "Manager") {
            qaAPIEndpoint("qas")
                .getQAsByManager(USERINFO.ID, this.state.status, this.state.address, this.state.name, this.state.type, this.state.currentPageNo, this.state.pageSize)
                .then(
                    res => {
                        this.setState({ qas: res.data, loading: false });
                    }
                );
        } else {
            qaAPIEndpoint("qas")
                .getQAsByAdmin(this.state.status, this.state.address, this.state.name, this.state.type, this.state.currentPageNo, this.state.pageSize)
                .then(
                    res => {
                        this.setState({ qas: res.data, loading: false });
                    }
                )
        }
    }
}
