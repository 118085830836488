import React, { Component } from 'react';
import { categoryAPIEndpoint } from '../../api';
import { ChangeMaterial } from './ChangeMaterial';
import { DeleteMaterial } from './DeleteMaterial';
import { NewMaterial } from './NewMaterial';

export class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryId: 1,
            materials: [],
            materialId: 0,
            checkpoints: [],
            checkpointId: 0,
            isNewMaterial: false,
            isChangeMaterial: false,
            isDeleteMaterial: false,
            isChangeCheckpoint: false,
        }
    }

    componentDidMount() {
        categoryAPIEndpoint('category')
            .getMaterials(this.state.categoryId)
            .then(materialRes => {
                var materialId = materialRes.data.length == 0 ? (this.state.categoryId == 4 ? 0 : -1) : materialRes.data[0].id;
                categoryAPIEndpoint('category')
                    .getCheckpoint(materialId)
                    .then(checkpointRes => {
                        this.setState({
                            materialId: materialId,
                            materials: materialRes.data,
                            checkpoints: checkpointRes.data
                        })
                    });
            });
    }

    selectCategory = e => {
        this.setState({ categoryId: e.target.value }, () => {
            categoryAPIEndpoint('category')
                .getMaterials(this.state.categoryId)
                .then(materialRes => {
                    var materialId = materialRes.data.length == 0 ? (this.state.categoryId == 4 ? 0 : -1) : materialRes.data[0].id;
                    categoryAPIEndpoint('category')
                        .getCheckpoint(materialId)
                        .then(checkpointRes => {
                            this.setState({
                                materialId: materialId,
                                materials: materialRes.data,
                                checkpoints: checkpointRes.data
                            });
                        })
                });
        });
    }

    selectMaterial = e => {
        this.setState({ materialId: e.target.value }, () => {
            categoryAPIEndpoint('category')
                .getCheckpoint(this.state.materialId)
                .then(res => {
                    this.setState({ checkpoints: res.data });
                });
        });

    }

    createNewMaterial = () => {
        this.setState({ isNewMaterial: true });
    }
    saveNewMaterial = e => {
        var name = document.getElementById("new-material-name").value;
        var saveData = {
            categoryId: this.state.categoryId,
            name: name
        }

        categoryAPIEndpoint('category')
            .saveMaterial(saveData)
            .then(saveRes => {
                categoryAPIEndpoint('category')
                    .getMaterials(this.state.categoryId)
                    .then(getRes => {
                        this.setState({
                            isNewMaterial: false,
                            materialId: saveRes.data.id,
                            materials: getRes.data,
                            checkpoints: []
                        });
                    });
            });

    }
    cancelNewMaterial = () => {
        this.setState({ isNewMaterial: false });
    }

    changeMaterial = () => {
        this.setState({ isChangeMaterial: true });
    }
    saveChangeMaterial = (newName) => {
        var updateMaterial = {
            id: this.state.materialId,
            categoryId: this.state.categoryId,
            name: newName
        };
        categoryAPIEndpoint('category')
            .changeMaterial(this.state.materialId, updateMaterial)
            .then(res => {
                categoryAPIEndpoint('category')
                    .getMaterials(this.state.categoryId)
                    .then(getRes => {
                        this.setState({
                            isChangeMaterial: false,
                            materials: getRes.data,
                        });
                    });

            });
    }
    cancelChangeMaterial = () => {
        this.setState({ isChangeMaterial: false });
    }

    deleteMaterial = () => {
        this.setState({ isDeleteMaterial: true });
    }
    saveDeleteMaterial = () => {
        categoryAPIEndpoint('category')
            .deleteMaterial(this.state.materialId)
            .then(res => {
                categoryAPIEndpoint('category')
                    .getMaterials(this.state.categoryId)
                    .then(getRes => {
                        this.setState({
                            isDeleteMaterial: false,
                            materialId: getRes.data.length == 0 ? (this.state.categoryId == 4 ? 0 : -1) : getRes.data[0].id,
                            materials: getRes.data,
                        });
                    });
            });
    }
    cancelDeleteMaterial = () => {
        this.setState({ isDeleteMaterial: false });
    }

    saveCheckpoint = () => {
        var materialId = this.state.categoryId != 4 ? document.getElementById("material").value : 0;
        if (!this.state.isChangeCheckpoint) {
            var saveData = {
                materialId: materialId,
                name: document.getElementById("checkpoint-name").value
            }
            categoryAPIEndpoint('category')
                .saveCheckpoint(saveData)
                .then(res => {
                    categoryAPIEndpoint('category')
                        .getCheckpoint(materialId)
                        .then(res => {
                            this.setState({ checkpoints: res.data });
                        });
                });
        } else {
            var data = {
                id: this.state.checkpointId,
                materialId: materialId,
                name: document.getElementById("checkpoint-name").value
            }
            categoryAPIEndpoint('category')
                .changeCheckpoint(this.state.checkpointId, data)
                .then(res => {
                    categoryAPIEndpoint('category')
                        .getCheckpoint(materialId)
                        .then(res => {
                            this.setState({
                                isChangeCheckpoint: false,
                                checkpoints: res.data
                            });

                        });
                });
        }
        document.getElementById("checkpoint-name").value = '';
    }

    changeCheckpoint = (id, name) => {
        document.getElementById("checkpoint-name").value = name;
        this.setState({
            isChangeCheckpoint: true,
            checkpointId: id
        });
    }

    deleteCheckpoint = (id) => {
        var materialId = this.state.categoryId != 4 ? document.getElementById("material").value : 0;
        categoryAPIEndpoint('category')
            .deleteCheckpoint(id)
            .then(res => {
                categoryAPIEndpoint('category')
                    .getCheckpoint(materialId)
                    .then(res => {
                        this.setState({
                            checkpoints: res.data
                        });
                    });
            })
    }

    render() {
        return (
            <div className="category">
                <h1>Category Management</h1>
                <div className="row my-5">
                    <div className="col-md-12 d-flex">
                        <span className="label">Category</span>
                        <select className="form-select" onChange={this.selectCategory}>
                            <option value="1">Foundation</option>
                            <option value="2">Roof</option>
                            <option value="3">Wet Area</option>
                            <option value="4">Tiling</option>
                        </select>
                    </div>
                </div>

                {
                    this.state.categoryId != 4 && (
                        <div className="row my-5">
                            <div className="col-md-12 d-flex">
                                <span className="label">Material</span>
                                <select id="material" className="form-select" value={this.state.materialId} onChange={this.selectMaterial}>
                                    {this.state.materials.length == 0 && (<option value="-1">No material</option>)}

                                    {
                                        this.state.materials.map((material, index) => {
                                            return (
                                                <option key={index} value={material.id}>{material.name}</option>
                                            )
                                        })
                                    }
                                </select>

                                <button className="btn btn-secondary mx-2" onClick={this.createNewMaterial}>New</button>
                                <button
                                    className="btn btn-secondary mx-2"
                                    onClick={this.changeMaterial}
                                    disabled={this.state.materials.length == 0 ? true : false}
                                >
                                    Change
                                </button>
                                <button
                                    className="btn btn-secondary mx-2"
                                    onClick={this.deleteMaterial}
                                    disabled={this.state.materials.length == 0 ? true : false}
                                >
                                    Delete
                                </button>
                            </div>

                            {
                                this.state.isNewMaterial &&
                                <NewMaterial
                                    saveNewMaterial={this.saveNewMaterial}
                                    cancelNewMaterial={this.cancelNewMaterial}
                                />
                            }

                            {
                                this.state.isChangeMaterial &&
                                <ChangeMaterial
                                    id={this.state.materialId}
                                    saveChangeMaterial={this.saveChangeMaterial}
                                    cancelChangeMaterial={this.cancelChangeMaterial}
                                />
                            }

                            {
                                this.state.isDeleteMaterial &&
                                <DeleteMaterial
                                    saveDeleteMaterial={this.saveDeleteMaterial}
                                    cancelDeleteMaterial={this.cancelDeleteMaterial}
                                />
                            }
                        </div>
                    )
                }


                <div className="row my-5">
                    <div className="col-md-12 d-flex">
                        <span className="label">Checkpoints</span>
                        <div className="checkpoints w-100">
                            {
                                this.state.checkpoints.map((checkpoint, index) => {
                                    return (
                                        <div className="checkpoint d-flex justify-content-between" key={index}>
                                            <p>{checkpoint.name}</p>
                                            <div className="action">
                                                <a id="checkpoint-change" className="mx-3" onClick={() => this.changeCheckpoint(checkpoint.id, checkpoint.name)}>Change</a>
                                                <a id="checkpoint-delete" className="ms-3" onClick={() => this.deleteCheckpoint(checkpoint.id)}>Delete</a>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="col-md-12 mt-3" style={{ 'paddingLeft': '100px' }}>
                        <textarea
                            id="checkpoint-name"
                            className="form-control"
                            rows="3"
                        >
                        </textarea>
                    </div>

                </div>

                <div className="row">
                    <div className="col-md-12 text-end">
                        <button className="btn btn-secondary" onClick={this.saveCheckpoint}>Save</button>
                    </div>
                </div>
            </div>
        )

    }
}