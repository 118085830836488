import React, { Component } from 'react';
import { qaAPIEndpoint, awsImageAPIEndpoint, commentAPIEndpoint, categoryAPIEndpoint, awsEmailAPIEndpoint, userAPIEndpoint } from '../../api';
import { Loading } from '../Utility/Loading';
import { QAheader } from '../Utility/QAheader';

import currentDateTime from '../Utility/fncustom';
import { USERINFO } from '../Auth/userinfo';
import jsPDF from 'jspdf';
import { Canvg } from 'canvg';

export class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imagesFromAws: [],          //  from Aws
            imagesToAdd: [],
            imagesToRemove: [],
            qadata: {},                 //  from db
            approver: {},                 
            creator: {},                 
            materials: [],
            checkpoints: [],
            checkpointIds: [],
            comment: '',
            loading: false,
        }
    }

    componentDidMount() {
        this.setState({ loading: true });
        const path = this.props.location.pathname;
        const qaid = path.split('/')[path.split('/').length - 1];
        qaAPIEndpoint("qas")
            .fetchById(qaid)
            .then(qaRes => {
                awsImageAPIEndpoint("aws")
                    .getAllKeysOfFolder(qaid)
                    .then(imageRes => {
                        categoryAPIEndpoint('category')
                            .getMaterials(qaRes.data.categoryId)
                            .then(materialRes => {
                                categoryAPIEndpoint('category')
                                    .getCheckpoint(qaRes.data.materialId)
                                    .then(checkpointRes => {
                                        var approverRequestId = qaRes.data.approverId;
                                        if (qaRes.data.approverId == 0) {
                                            approverRequestId = 1;
                                        }// No approver yet. in submitted status, use admin with id 1 for default
                                        userAPIEndpoint('users').fetchById(approverRequestId)
                                            .then(approverRes => {
                                                userAPIEndpoint('users').fetchById(qaRes.data.userId)
                                                    .then(creatorRes => {
                                                        this.setState({
                                                            qadata: qaRes.data,
                                                            imagesFromAws: imageRes.data,
                                                            materials: materialRes.data,
                                                            checkpoints: checkpointRes.data,
                                                            approver: approverRes.data,
                                                            creator: creatorRes.data,
                                                            loading: false
                                                        });
                                                    });
                                            });
                                           
                                    });
                            });
                    });
            });
    }

    upload = () => {
        document.getElementById('imageUpload').click();
    }
    handleAddImage = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const images = this.state.imagesToAdd.slice();
            for (let i = 0; i < e.target.files.length; i++) {
                images.push(e.target.files[i]);
            }

            this.setState({ imagesToAdd: images });
        }
    }
    cancelImage = i => {
        var imgs = this.state.imagesToAdd.slice();
        imgs.splice(i, 1);
        this.setState({ imagesToAdd: imgs });
    }
    cancelAwsImage = (i, awsKey) => {
        var images = this.state.imagesFromAws.slice();
        images.splice(i, 1);

        this.setState({
            imagesToRemove: this.state.imagesToRemove.slice().concat(awsKey),
            imagesFromAws: images
        });
    }

    addWaterMark = (doc) => {
        var totalPages = doc.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
            //image water mark
            doc.setPage(i);
            doc.saveGraphicsState();
            doc.setGState(new doc.GState({ opacity: 0.1 }));
            var width = doc.internal.pageSize.getWidth();
            var height = doc.internal.pageSize.getHeight();
            var img = new Image()
            img.src = 'images/watermark.png'
            doc.addImage(img, 'PNG', 0, 0, width, height);
            doc.restoreGraphicsState();
            //text watermark
            doc.setTextColor(150);
            doc.text(50, doc.internal.pageSize.height - 30, 'WL Waterproofing');
        }
        return doc;
    }


    handleCategory = ct => {
        categoryAPIEndpoint('category')
            .getMaterials(ct)
            .then(materialRes => {
                var materialId = materialRes.data.length == 0 ? ct == 4 ? 0 : -1 : materialRes.data[0].id;
                categoryAPIEndpoint('category')
                    .getCheckpoint(materialId)
                    .then(checkpointRes => {
                        this.setState({
                            qadata: { ...this.state.qadata, categoryId: ct, materialId: materialId },
                            materials: materialRes.data,
                            checkpoints: checkpointRes.data,
                            checkpointIds: []
                        });
                    });
            });
    }

    handleCheckpoint = e => {
        const checkpointIds = this.state.qadata.checkpointIds.split(',');
        const i = checkpointIds.indexOf(e.target.value);
        if (i > -1) {
            checkpointIds.splice(i, 1);
            this.setState({ qadata: { ...this.state.qadata, checkpointIds: checkpointIds.join() } });
        } else {
            this.setState({ qadata: { ...this.state.qadata, checkpointIds: checkpointIds.concat(e.target.value).join() } });
        }
    }

    handleMaterial = e => {
        this.setState({ materialId: e.target.value }, () => {
            categoryAPIEndpoint('category')
                .getCheckpoint(e.target.value)
                .then(checkpointRes => {
                    this.setState({
                        checkpoints: checkpointRes.data
                    });
                });
        });
    }

    handleComment = e => {
        this.setState({ comment: e.target.value });
    }
    handleChange = e => {
        this.setState({ qadata: { ...this.state.qadata, [e.target.id]: e.target.value } });
    }

    /* Result process */
    handleClear = () => {
        this.setState({ qadata: { ...this.state.qadata, comment: '', address: '' } });
    }

    handleSave = status => {
        this.setState({
            loading: true,
        },
          () => {
            awsImageAPIEndpoint('aws')
                .deleteKeys(this.state.imagesToRemove)
                .then(awsImageDeleteRes => {
                    var formData = new FormData();
                    var images = this.state.imagesToAdd;

                    formData.enctype = "multipart/form-data";
                    for (let i = 0; i < images.length; i++) {
                        formData.append("files", images[i], images[i].name);
                    }
                    awsImageAPIEndpoint('aws')
                        .uploadImages(this.state.qadata.id, formData)
                        .then(awsImageAddRes => {
                            const dataComment = {
                                qaid: this.state.qadata.id,
                                userid: USERINFO.ID,
                                cmt: this.state.comment,
                                commentTime: currentDateTime()
                            }
                            commentAPIEndpoint('comments')
                                .save(dataComment)
                                .then(res => {
                                    if (status == "Approved" || status == "Rejected") {
                                        var dataEmail = {
                                            qaid: this.state.qadata.id,
                                            status: status,
                                        }
                                        awsEmailAPIEndpoint('emailaws')
                                            .sendEmail(dataEmail)
                                            .then(emailRes => {
                                                if (emailRes.data == true) {
                                                    this.setState({ qadata: { ...this.state.qadata, status: status, approverid: USERINFO.ID } }, () => {
                                                        var tempData = this.state.qadata
                                                        if (this.state.materialId) { tempData.materialId = this.state.materialId }
                                                        qaAPIEndpoint('qas')
                                                            .put(this.state.qadata.id, tempData)
                                                            .then(res => {
                                                                this.setState({ loading: false }, () => { this.props.history.push("/my-qas") })
                                                                document.location.href = `/detail-qa-form/${this.state.qadata.id}`
                                                             });
                                                    })
                                                } else {
                                                    alert('Either creator\'s or approver\'s email is not verified , please contact Admin to verify email address in Amazon SES service.');
                                                    window.history.go(-1);
                                                }
                                            })
                                            .catch(error => alert(error.response.data));
                                    } else {
                                        this.setState({ qadata: { ...this.state.qadata, status: status } }, () => {
                                            var tempData = this.state.qadata
                                            if (this.state.materialId) { tempData.materialId = this.state.materialId }
                                            qaAPIEndpoint('qas')
                                                .put(this.state.qadata.id, tempData)
                                                .then(res => {
                                                    this.setState({ loading: false }, () => {this.props.history.push("/my-qas") })
                                                    document.location.href = `/detail-qa-form/${this.state.qadata.id}`
                                                });
                                        })

                                    }

                                });
                        })
                })
        });

    }
    handleGoBack = () => {
        window.history.go(-1);
    }

    handlePdfDownload = () => {
        this.setState({ loading: true });
        awsImageAPIEndpoint('aws')
            .getAllImagesOfFolder(this.state.qadata.id)
            .then(res => {
                var doc = new jsPDF("p", "pt", "b4"),   //  708.8 × 1000.8 points
                    categories = ["Foundation", "Roof", "Wet Area", "Tiling"];
                var cursorX, cursorY = 0;
                var fullWidth = doc.internal.pageSize.getWidth();
                var leftPadding = 40;
                var topMargine = 40;
                var logoHight = 120;
                var lineSpacer = 40;
                var smallLineSpacer = 5;
                doc.addImage(process.env.PUBLIC_URL + "images/logo.jpg", "jpg", leftPadding, topMargine, fullWidth - 2 * leftPadding, 120);
                cursorX = leftPadding;
                cursorY = topMargine + logoHight + lineSpacer;
                doc.setFont("times", "bold");
                doc.setFontSize(20);
                doc.setTextColor(0, 0, 0);

                //Category text
                doc.text("Category:", cursorX, cursorY);
                cursorX += doc.getTextDimensions("Category:").w + 20;
                doc.text(categories[this.state.qadata.categoryId - 1], cursorX, cursorY);
                cursorX = leftPadding;
                cursorY += doc.getTextDimensions("Category:").h + smallLineSpacer;
                //Job sheet
                doc.setFontSize(18);
                doc.text("Job Sheet Number:   WL-QA-" + this.state.qadata.id, cursorX, cursorY);
                cursorX += doc.getTextDimensions("Job Sheet Number:").w + 8 * lineSpacer;
                doc.text("Status: ", cursorX, cursorY);
                cursorX += doc.getTextDimensions("Status: ").w;
                doc.setTextColor(0, 0, 255);
                doc.text(this.state.qadata.status, cursorX, cursorY);
                doc.setTextColor(0, 0, 0);
                
                cursorX = leftPadding;
                cursorY += doc.getTextDimensions("Job Sheet Number").h + 4 * smallLineSpacer;              
                doc.setFontSize(14);
                doc.text("Property Address:", cursorX, cursorY);
                cursorX += doc.getTextDimensions("Property Address:  ").w
                doc.setFont("times", "normal");
                doc.setFontSize(12);
                doc.text(this.state.qadata.address, cursorX, cursorY);

                cursorX = leftPadding;
                cursorY += doc.getTextDimensions("Property Address:").h + 2 * smallLineSpacer;

                //  material used
                if (this.state.qadata.categoryId != 4) {
                    doc.setFont("times", "bold");
                    doc.text("Material Used:", cursorX, cursorY);
                    doc.setFont("times", "normal");
                    cursorX += doc.getTextDimensions("Material Used:").w + 2 * smallLineSpacer
                    this.state.materials.map((m, i) => {
                        if (m.id == this.state.qadata.materialId) {
                            doc.text(m.name, cursorX, cursorY);
                        }
                    })
                } else {
                    doc.text("Material Used: " + this.state.qadata.material, cursorX, cursorY);
                    cursorY += doc.getTextDimensions("Material Used:").h + smallLineSpacer
                    doc.text("Grout Color: " + this.state.qadata.grout, cursorX, cursorY);
                    cursorY += doc.getTextDimensions("Grout Color:").h + smallLineSpacer
                    doc.text("Sealant Color: " + this.state.qadata.sealant, cursorX, cursorY);
                }
                cursorX = leftPadding;
                cursorY += doc.getTextDimensions("Sealant Color: ").h + lineSpacer

                //  checkpoint
                doc.setFont("times", "bold");
                doc.text("Check Points", cursorX, cursorY);
                cursorY += doc.getTextDimensions("Check Points").h + smallLineSpacer;
                doc.setFont("times", "normal");
                var y = 220,
                    maxLineWidth = 600,
                    lineHeight = 1.2,
                    fontSize = 12,
                    indent = 6,
                    checkPointX = leftPadding + indent;

                
                this.state.checkpoints.map((c, i) => {
                    cursorX = checkPointX;
                    var textLines = doc.splitTextToSize(c.name, maxLineWidth),
                        textHeight = textLines.length * fontSize * lineHeight;                    
                    var checked = (this.state.qadata.checkpointIds != undefined ? this.state.qadata.checkpointIds : "").split(',').includes(String(c.id));
                    if (checked) {
                        doc.addImage(process.env.PUBLIC_URL + "images/icons/checked-checkbox.png", "png", cursorX, cursorY, 12, 12);
                    } else {
                        doc.addImage(process.env.PUBLIC_URL + "images/icons/unchecked-checkbox.png", "png", cursorX, cursorY, 12, 12);
                    }
                    cursorX += 12 + 2 * smallLineSpacer;
                    cursorY += 2* smallLineSpacer;
                    doc.text("Done", cursorX, cursorY);
                    cursorX += doc.getTextDimensions("Done").w + 2 * smallLineSpacer
                    doc.text(textLines, cursorX, cursorY);
                    cursorY += textHeight + smallLineSpacer;
                })
                cursorX = leftPadding;
                cursorY += 2* smallLineSpacer


                //Create/Approve Name
                doc.addPage('b4', 'p');
                cursorX = leftPadding;
                cursorY = 2 * topMargine;

                doc.setFont(undefined, 'bold');
                doc.text("Creator Name:", cursorX, cursorY)
                cursorX += 3 * lineSpacer;
                doc.setFont(undefined, 'normal');
                doc.text(this.state.creator.name, cursorX, cursorY);
                cursorY += 12 + smallLineSpacer;
                cursorX = leftPadding;
                doc.setFont(undefined, 'bold');
                doc.text("Email:", cursorX, cursorY);
                cursorX += 3 * lineSpacer;
                doc.setFont(undefined, 'normal');
                doc.text(this.state.creator.email, cursorX, cursorY);
                cursorY += 12 + 2* smallLineSpacer;

                cursorX = leftPadding;
                doc.setFont(undefined, 'bold');
                doc.text("Approver Name:", cursorX, cursorY)
                cursorX += 3 * lineSpacer;
                doc.setFont(undefined, 'normal');
                doc.text(this.state.approver.name, cursorX, cursorY);
                cursorY += 12 + smallLineSpacer;
                cursorX = leftPadding;
                doc.setFont(undefined, 'bold');
                doc.text("Email:", cursorX, cursorY);
                cursorX += 3 * lineSpacer;
                doc.setFont(undefined, 'normal');
                doc.text(this.state.approver.email, cursorX, cursorY);
                



                //Comment History
                cursorX = leftPadding;
                cursorY += lineSpacer;
                doc.setFontSize(14);
                doc.setFont(undefined, 'bold');
                doc.text("Comment History", cursorX, cursorY);
                doc.setFont(undefined, 'normal');
                cursorY += 6 * smallLineSpacer
                this.state.qadata.comments.map((comment, index) => {
                    cursorX = leftPadding;
                    doc.setFontSize(12);
                    doc.setTextColor(0, 0, 255);
                    doc.text("[" + comment.commentTime + "]", cursorX, cursorY);
                    doc.setTextColor(0, 0, 0);
                    cursorX += 3 * lineSpacer
                    
                    doc.text("Created by " + comment.user.name, cursorX, cursorY);
                    cursorX = leftPadding;
                    cursorY += 12 +  smallLineSpacer
                    doc.setFontSize(10);
                    var maxCommentWidth = fullWidth - 2 * leftPadding,
                        commentLines = doc.splitTextToSize(comment.cmt, maxCommentWidth),
                        commentHeight = commentLines.length * fontSize * lineHeight;
                    doc.text(commentLines, cursorX, cursorY);
                    cursorY += commentHeight + 2 * smallLineSpacer
                });
         

                //  Upload pictures
                doc.addPage('b4', 'p');
                y = 0;
                var y1 = y,
                    imageGapOnPdf = 40,
                    topMargin = 100,
                    bottomMargin = 100,
                    leftMargin = 55,
                    rightMargin =55,
                    wImageOnPdf = (doc.internal.pageSize.getWidth() - leftMargin - rightMargin - imageGapOnPdf ) / 2,
                    hImageOnPdf = (doc.internal.pageSize.getHeight() - topMargin - bottomMargin - imageGapOnPdf ) / 2,
                    x = leftMargin;
                y += topMargin;

                res.data.map((img, i) => {
                    if (y > (bottomMargin + hImageOnPdf *2 )) {// new page
                        doc.addPage('b4', 'p');
                        x = leftMargin;
                        y = topMargin;
                    }
                    var imageType = img.split(".").pop();
                    doc.addImage("data:image/" + imageType + ";base64," + img, imageType, x, y, wImageOnPdf, hImageOnPdf);

                    x += wImageOnPdf + imageGapOnPdf ;
                    if (x >= 2 * wImageOnPdf) { //new row
                        x = leftMargin;
                        y += hImageOnPdf + imageGapOnPdf;
                    }
                });
                doc = this.addWaterMark(doc);
                doc.save("WL-QA-" + this.state.qadata.id + ".pdf");
                this.setState({ loading: false });

            });
    }

    render() {
        const status = this.state.qadata.status;
        return (
            <>
                {
                    this.state.loading ? <Loading /> : ""
                }

                <div className="qa-content">
                    <QAheader
                        qaNo={this.state.qadata.id}
                        status={this.state.qadata.status}
                        categoryId={this.state.qadata.categoryId}
                        handleCategory={this.handleCategory}
                    />

                    <div className="mt-5">
                        <div className="row mb-3">
                            <div className="col-md-8 d-flex">
                                <span className="h5 text-nowrap">Property Address:</span>
                                <input
                                    id="address"
                                    className="search form-control form-control-sm ms-2"
                                    placeholder="109 Queen Street, Auckland, New Zealand"
                                    value={this.state.qadata.address || ''}
                                    onChange={this.handleChange}
                                    disabled={!this.isFullControl(USERINFO.ROLE)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-7 checkpoints">
                                <span className="h6">Check Point</span>
                                {
                                    this.state.checkpoints.map((checkpoint, index) => {
                                        var checked = (this.state.qadata.checkpointIds != undefined ? this.state.qadata.checkpointIds : "").split(',').includes(String(checkpoint.id));
                                        return (
                                            <div key={index} className="check-point d-flex align-items-start gap-4">
                                                <div className="check-point-done d-flex align-items-center gap-2 mb-3">
                                                    <input
                                                        type="checkbox"
                                                        value={checkpoint.id}
                                                        onChange={this.handleCheckpoint}
                                                        checked={checked}
                                                    />
                                                    <span>Done</span>
                                                </div>
                                                <div className="check-point-description">
                                                    <p>{checkpoint.name}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="col-md-5">
                                {
                                    (this.state.qadata.categoryId == 1 || this.state.qadata.categoryId == 2 || this.state.qadata.categoryId == 3) &&
                                    (
                                        <>
                                            <span>Material Used:</span>
                                            <select className="form-select"
                                                onChange={this.handleMaterial}
                                                value={this.state.value}
                                                defaultValue={this.state.qadata.materialId}
                                            >
                                                {this.state.materials.length == 0 && (<option value="-1">No material</option>)}
                                                {
                                                    this.state.materials.map((material, index) => {
                                                        return (
                                                            <option key={index} value={material.id}>{material.name}</option>
                                                        )
                                                    })
                                                }

                                            </select>
                                        </>
                                    )
                                }{
                                    this.state.qadata.categoryId == 4 &&
                                    (
                                        <div className="options d-flex flex-column gap-3 p-3 w-75">
                                            <div className="material d-flex">
                                                <span className="text-nowrap">Material Used:</span>
                                                <input type="text" id="material" className="form-control form-control-sm ms-2" placeholder="Rubber" value={this.state.qadata.material} onChange={this.handleChange} />
                                            </div>

                                            <div className="grout d-flex">
                                                <span className="text-nowrap">Grout Color:</span>
                                                <input type="text" id="grout" className="form-control form-control-sm ms-2" placeholder="Red" value={this.state.qadata.grout} onChange={this.handleChange} />
                                            </div>

                                            <div className="sealant d-flex">
                                                <span className="text-nowrap">Sealant Color:</span>
                                                <input type="text" id="sealant" className="form-control form-control-sm ms-2" placeholder="Blue" value={this.state.qadata.sealant} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                        <hr />

                        <div className="row">
                            <div className="col-md-7">
                                <div className="d-flex">
                                    <span className="h5">Upload Pictures: </span>
                                    <span className="ms-3">Support jpg, gif, bmp, png format size limit: 2GB</span>
                                </div>
                                <div className="d-flex flex-wrap gap-4 mt-3 overflow-auto" style={{ 'height': '200px' }}>
                                    {
                                        this.state.imagesFromAws.map((image, index) => {
                                            return (
                                                <div key={index} className="qa-img-thumbnail m-1">
                                                    <img
                                                        src={image.path}
                                                        alt="Thumb"
                                                    />
                                                    <div className="qa-img-cancel">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "images/icons/close.png"}
                                                            onClick={() => this.cancelAwsImage(index, image.key)}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    {
                                        this.state.imagesToAdd.map((image, index) => {
                                            return (
                                                <div key={index} className="qa-img-thumbnail m-1">
                                                    <img
                                                        src={URL.createObjectURL(image)}
                                                        alt="Thumb"
                                                    />
                                                    <div className="qa-img-cancel">
                                                        <img src={process.env.PUBLIC_URL + "images/icons/close.png"} onClick={() => this.cancelImage(index)} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <input
                                    className="btn"
                                    id='imageUpload'
                                    name="imageUpload"
                                    type="file"
                                    accept="image/*"
                                    onChange={this.handleAddImage}
                                    hidden multiple
                                />
                                <button
                                    className="btn btn-secondary mt-3"
                                    onClick={this.upload}
                                    disabled={!this.isFullControl(USERINFO.ROLE)}
                                >
                                    Upload
                                </button>
                            </div>
                            <div className="col-md-5 d-flex flex-column">
                                <span className="h5">Comment History</span>
                                <ul className="d-flex flex-column gap-3 mt-5 p-0">
                                    {
                                        this.state.qadata.comments && this.state.qadata.comments.map((comment, i) => {
                                            return (
                                                <li key={i} className="d-flex flex-column">
                                                    <span className="comment-time">
                                                        {comment.commentTime}&nbsp;
                                                        <small className="created-by-comment">Created by {comment.user.name}</small>
                                                    </span>

                                                    <span className="comment-text">{comment.cmt}</span>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <div className="row my-5">
                            {
                                status != "Approved" && (
                                    <div className="col-md-5 offset-md-7 d-flex flex-column">
                                        <span className="h5">Comments</span>
                                        <textarea
                                            id="comment"
                                            className="form-control"
                                            rows="6"
                                            onChange={this.handleComment}
                                            value={this.state.qadata.comment}>
                                        </textarea>
                                    </div>
                                )
                            }

                            {this.buttonGroup(this.state.qadata.status)}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    //  full or only comment controll
    isFullControl(userRole) {
        if (userRole == "Admin") {
            return true;
        } else if (userRole == "Manager") {
            if (this.state.qadata.userId == USERINFO.ID && (this.state.qadata.status == "New" || this.state.qadata.status == "Rejected")) {
                return true;
            }
        } else {    //  Employee
            if (this.state.qadata.userId == USERINFO.ID && (this.state.qadata.status == "New" || this.state.qadata.status == "Rejected")) {
                return true;
            }
        }
        return false;
    }

    //  button variation according to QA state(New, Submitted, Approved, Rejected)
    buttonGroup(state) {
        if (state == "New") {
            return (
                <div className="col-md-12 text-end mt-5 result-btns">
                    <button className="btn btn-secondary mx-2" onClick={this.handleGoBack}>Cancel</button>
                    <button className="btn btn-secondary mx-2" onClick={() => this.handleSave("New")}>Save</button>
                    <button className="btn btn-secondary mx-2" onClick={() => this.handleSave("Submitted")}>Submit</button>
                </div>
            )
        } else if (state == "Submitted") {
            if (USERINFO.ROLE != "Employee") {
                return (
                    <div className="col-md-12 text-end mt-5 result-btns">
                        <button className="btn btn-secondary mx-2" onClick={() => this.handleSave("Rejected")}>Reject</button>
                        <button className="btn btn-secondary mx-2" onClick={() => this.handleSave("Approved")}>Approve</button>
                    </div>
                )
            }
        } else if (state == "Rejected") {
            return (
                <div className="col-md-12 text-end mt-5 result-btns">
                    <button className="btn btn-secondary mx-2" onClick={() => this.handleSave("New")}>Save</button>
                    <button className="btn btn-secondary mx-2" onClick={() => this.handleSave("Submitted")}>Submit</button>
                </div>
            )
        } else if (state == "Approved") {
            return (
                <div className="col-md-12 text-end mt-5 result-btns">
                    <button className="btn btn-secondary mx-2" onClick={this.handlePdfDownload}>
                        <img src={process.env.PUBLIC_URL + "images/icons/logopdf(white).png"} width="24px" />
                        Download All report
                    </button>
                    <button className="btn btn-secondary mx-2" onClick={this.handleGoBack}>Finish</button>
                </div>
            )
        } else {

        }
    }
}
