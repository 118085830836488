import React, { Component } from 'react';

const paginationLength = 5;
export class QApagination extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var countOfPages = Math.ceil(this.props.totalRows / this.props.pageSize);
        var startNoOfPagination = Math.floor((this.props.currentPageNo - 1) / paginationLength) * paginationLength + 1;

        if (this.props.currentPageNo == startNoOfPagination) {
            if (this.props.currentPageNo + 1 >= countOfPages) {
                startNoOfPagination -= paginationLength;
            }
        }

        if ((this.props.currentPageNo == countOfPages && startNoOfPagination + 1 == countOfPages)) {
            startNoOfPagination -= paginationLength;
        }

        if (startNoOfPagination < 1) {
            startNoOfPagination = 1;
        }

        return (
            <div className="qas-pagination d-flex gap-3">
                <div className="d-flex align-items-center">
                    <span>Total:</span>
                    <span>{this.props.totalRows}</span>
                </div>

                <div className="d-flex align-items-center">
                    <span>Page</span>
                    <select onChange={this.props.pickPageSize} value={this.props.pageSize}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                    </select>
                    <span>rows</span>
                </div>
                <div className="page-no d-flex gap-3">
                    <button
                        className="btn"
                        onClick={this.props.pagePrev}
                    >
                        <img src="images/icons/chevron-left-solid.svg" width="10" />
                    </button>
                    {
                        [...Array(7)].map((el, i) => {
                            var current = this.props.currentPageNo == i + startNoOfPagination ? " active" : "";
                            if (i + startNoOfPagination <= countOfPages) {
                                if (startNoOfPagination + 6 < countOfPages) {
                                    if (i == 5) {
                                        return (
                                            <button key={i} className="btn">...</button>
                                        )
                                    } else if (i == 6) {
                                        return (
                                            <button
                                                key={i}
                                                className="btn"
                                                onClick={() => this.props.pickPageNo(countOfPages)}
                                            >
                                                {countOfPages}
                                            </button>
                                        )
                                    } else {
                                        return (
                                            <button
                                                key={i}
                                                className={`btn${current}`}
                                                onClick={() => this.props.pickPageNo(i + startNoOfPagination)}
                                            >
                                                {i + startNoOfPagination}
                                            </button>
                                        )
                                    }

                                } else {
                                    return (
                                        <button
                                            key={i}
                                            className={`btn${current}`}
                                            onClick={() => this.props.pickPageNo(i + startNoOfPagination)}
                                        >
                                            {i + startNoOfPagination}
                                        </button>
                                    )
                                }
                            }
                        })
                    }
                    <button
                        className="btn"
                        onClick={this.props.pageNext}
                    >
                        <img src="images/icons/chevron-right-solid.svg" width="10" />
                    </button>
                </div>
            </div>
        )
    }
}