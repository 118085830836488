import axios from 'axios';

//export const BASE_URL = 'https://localhost:7159/';  //  Development
export const BASE_URL = 'https://wlwaterproofing.com/';  //  Production(server uri)

/* Login */
export const loginAPIEndpoint = endpoint => {
    let url = BASE_URL + 'api/' + endpoint + '/';

    return {
        post: newRecord => axios.post(url, newRecord)
    }
}

/* User */
export const userAPIEndpoint = endpoint => {
    let url = BASE_URL + 'api/' + endpoint + '/';

    return {
        fetchById: id => axios.get(url + id),
        put: (id, updatedRecord) => axios.put(url + id, updatedRecord)
    }
}

/* QA form */
export const qaAPIEndpoint = endpoint => {
    let url = BASE_URL + 'api/' + endpoint + '/';

    return {
        getNewQANo: () => axios.get(url + "max"),  // new QA form No
        getRowCountOfQAsByEmployee: userid => axios.get(url + "employee/count?userid=" + userid),
        getRowCountOfQAsByManager: (userid) => axios.get(url + "manager/count?userid=" + userid),
        getRowCountOfQAsByAdmin: () => axios.get(url + "admin/count"),
        getQAsByEmployee: (userid, status, address, name, type, pageNo, pageSize) => axios.get(url + 'employee?userid=' + userid + '&status=' + status + '&address=' + address + '&name=' + name + '&type=' + type + '&pageno=' + pageNo + '&pagesize=' + pageSize), //  My QA list
        getQAsByManager: (userid, status, address, name, type, pageNo, pageSize) => axios.get(url + 'manager?userid=' + userid + '&status=' + status + '&address=' + address + '&name=' + name + '&type=' + type + '&pageno=' + pageNo + '&pagesize=' + pageSize),
        getQAsByAdmin: (status, address, name, type, pageNo, pageSize) => axios.get(url + 'admin?status=' + status + '&address=' + address + '&name=' + name + '&type=' + type + '&pageno=' + pageNo + '&pagesize=' + pageSize),
        fetchById: id => axios.get(url + id),                               //  Detail
        post: newRecord => axios.post(url, newRecord),                      //  New(new QA creating)
        put: (id, updatedRecord) => axios.put(url + id, updatedRecord),
        delete: id => axios.delete(url + id)
    }
}
/* Comment */
export const commentAPIEndpoint = endpoint => {
    let url = BASE_URL + 'api/' + endpoint + '/';

    return {
        save: newRecord => axios.post(url, newRecord),
        getByQAid: qaId => axios.get(url + 'qa?qaid=' + qaId),
    }
}

/*  Category management */
export const categoryAPIEndpoint = endpoint => {
    let url = BASE_URL + 'api/' + endpoint + '/';

    return {
        getMaterial: id => axios.get(url + "material/" + id),
        getMaterials: categoryId => axios.get(url + "material?categoryId=" + categoryId),
        saveMaterial: newRecord => axios.post(url + "material", newRecord),
        changeMaterial: (id, updateRecord) => axios.put(url + "material/" + id, updateRecord),
        deleteMaterial: id => axios.delete(url + "material/" + id),
        getCheckpoint: materialId => axios.get(url + "material/checkpoint?materialId=" + materialId),
        saveCheckpoint: newRecord => axios.post(url + "material/checkpoint", newRecord),
        changeCheckpoint: (id, updateRecord) => axios.put(url + "material/checkpoint/" + id, updateRecord),
        deleteCheckpoint: id => axios.delete(url + "material/checkpoint/" + id)
    }
}

/* Aws S3 image(upload, get) */
export const awsImageAPIEndpoint = endpoint => {
    let url = BASE_URL + 'api/' + endpoint + '/';

    return {
        uploadImages: (id, imageData) => axios.post(url + id, imageData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            maxBodyLength: Infinity,
            maxContentLength: Infinity, 
            emulateJSON: true
        }),
        getAllKeysOfFolder: id => axios.get(url + id),
        getAllImagesOfFolder: id => axios.get(url + "base64/" + id),
        deleteKeys: keys => axios.post(url, keys)
    }
}

/*  Aws email    */
export const awsEmailAPIEndpoint = endpoint => {
    let url = BASE_URL + 'api/' + endpoint + '/';

    return {
        sendEmail: emailData => axios.post(url, emailData)
    }
}