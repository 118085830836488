import React, { Component } from 'react';
import { categoryAPIEndpoint } from '../../api';

export class ChangeMaterial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: ''
        }
    }
    componentDidMount() {
        categoryAPIEndpoint('category')
            .getMaterial(this.props.id)
            .then(res => {
                this.setState({ name: res.data.name });
            })
    }
    changeMaterial = e => {
        this.setState({ name: e.target.value });
    }
    render() {
        return (
            <div className="ct-new">
                <div className="card">
                    <div className="card-header">Change</div>
                    <div className="card-body">
                        <p>Material name</p>
                        <input id="change-material-name" className="form-control my-3" value={this.state.name} onChange={this.changeMaterial} />
                        <div className="d-flex gap-4">
                            <button className="btn btn-secondary" onClick={() => this.props.saveChangeMaterial(this.state.name)}>Save</button>
                            <button className="btn btn-secondary" onClick={this.props.cancelChangeMaterial}>Cancel</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}