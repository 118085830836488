import React, { Component } from 'react';

const categories = [
    {
        id: 1,
        name: "Foundation",
        img: "images/icons/cloud-solid.svg"
    },
    {
        id: 2,
        name: "Roof",
        img: "images/icons/cloud-solid.svg"
    },
    {
        id: 3,
        name: "Wet Area",
        img: "images/icons/cloud-solid.svg"
    },
    {
        id: 4,
        name: "Tiling",
        img: "images/icons/cloud-solid.svg"
    }
];

export class QAheader extends Component {
    render() {
        return (
            <div className="qa-header">
                <ul className="d-flex justify-content-center flex-wrap h3 p-0">
                    {
                        categories.map((c, i) => {
                            var active = c.id == this.props.categoryId ? "active" : "";
                            return (
                                <li key={i} className={`nav-item ${active}`} onClick={() => this.props.handleCategory(c.id)}>
                                    <img src={process.env.PUBLIC_URL + c.img} width="30px" alt="category" />
                                    <span>{c.name}</span>
                                </li>
                            )
                        })
                    }
                </ul>

                <ul className="d-flex justify-content-center flex-wrap h4 p-0">
                    <li className="nav-item">
                        QA Number: WL-QA-{this.props.qaNo}
                    </li>
                    <li className="nav-item">
                        Status: {this.props.status}
                    </li>
                </ul>
            </div>
        )
    }
}