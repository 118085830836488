import React, { Component } from 'react';
/*import { Route } from 'react-router';*/
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { New } from './components/QA/New';

import './custom.css'
import { Login } from './components/Auth/Login';
import { User } from './components/User';
import { MyQAs } from './components/QA/MyQAs';
import { Detail } from './components/QA/Detail';
import { Category } from './components/Category';


export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Router>
                <Layout>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/login' component={Login} />
                    {/*<Route exact path='/new-qa-form' component={New} />
                    <Route exact path='/detail-qa-form' component={Detail} />
                    <Route exact path='/my-qas' component={MyQAs} />
                    <Route exact path='/user' component={User} />
                    <Route exact path='/category' component={Category} />*/}
                    <Switch>
                        <PrivateRoute exact path="/new-qa-form" component={New} />
                        <PrivateRoute path='/detail-qa-form' component={Detail} />
                        <PrivateRoute exact path="/my-qas" component={MyQAs} />
                        <PrivateRoute exact path="/user" component={User} />
                        <PrivateRoute exact path="/category" component={Category} />
                    </Switch>
                </Layout>
            </Router>
        );
    }
}
