import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { userAPIEndpoint } from '../../api';

export class User extends Component {
    constructor(props) {
        super(props);
        this.state = { user: {}, loading: true };
    }

    componentDidMount() {
        const id = JSON.parse(localStorage.userAuthenticated).id;

        userAPIEndpoint("users")
            .fetchById(id)
            .then(
                res => {
                    this.setState({ user: res.data, loading: false });
                }
            );
    }

    handleChange = e => {
        this.setState({ user: { ...this.state.user, [e.target.id]: e.target.value } });
    }

    handleUpdate = () => {
        const id = JSON.parse(localStorage.userAuthenticated).id;

        userAPIEndpoint("users")
            .put(id, this.state.user)
            .then(res => { console.log(res) });
    }
    render() {
        return (
            <div className="d-flex flex-column gap-3">
                <h1>My Account</h1>
                <div className="row">
                    <div className="col-md-6">
                        <span>Name</span>
                        <input
                            id="name"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.user.name || ''}
                        />
                    </div>
                    <div className="col-md-6">
                        <span>Role</span>
                        <input
                            id="role"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.user.role || ''}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <span>Email</span>
                        <input
                            id="email"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.user.email || ''}
                        />
                    </div>
                    <div className="col-md-6">
                        <span>Password</span>
                        <input
                            type="password"
                            id="password"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.user.password || ''}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <button
                            className="btn btn-secondary"
                            onClick={this.handleUpdate }
                        >
                            Update
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
