import React from "react";
import { Route, Redirect } from "react-router-dom";
import { USERINFO } from '../Auth/userinfo';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            USERINFO.ID > 0 ? (
                <Component {...props} />
            ) : (
                <Redirect to="/login" />
            )
        }
    />
);

export default PrivateRoute;