import React, { Component } from 'react';

export class Home extends Component {
  //static displayName = Home.name;

  render () {
    return (
      <div>
        <h1>Wl Waterproofing QA System</h1>
        <p>Welcome to your new QA System, built by: WL WaterProofing</p>     
      </div>
    );
  }
}
