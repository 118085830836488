import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { loginAPIEndpoint } from '../../api';

export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            email: '',
            password: ''
        };
    }

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value })
    }
    handleSubmit = async e => {
        e.preventDefault();
        const data = { 'email': this.state.email, 'password': this.state.password };

        loginAPIEndpoint('auth')
            .post(data)
            .then(res => {
                localStorage.setItem("userAuthenticated", JSON.stringify(res.data[0]));
                /*this.props.history.push("/my-qas");*/
                window.location.href = "/my-qas";
            })
            .catch(err => console.log(err));
    }

    render() {
        return (
            <div className="login row justify-content-center align-items-center min-vh-100">
                <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                    <form onSubmit={this.handleSubmit} method="post">
                        <div className="mb-3">
                            <input className="form-control" id="email" type="email" placeholder="Email address" onChange={this.handleChange} />
                        </div>
                        <div className="mb-3">
                            <input className="form-control" id="password" type="password" placeholder="Password" onChange={this.handleChange} />
                        </div>

                        <div className="mb-3">
                            <button className="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">Log in</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
