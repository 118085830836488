import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
    handleLogOut() {
        localStorage.removeItem("userAuthenticated");
        window.location.href = "./login";
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow" light>
                    <Container>
                        <div className="d-flex gap-2">
                            <button className="header-toggle-btn btn" onClick={this.props.toggleSidemenu}>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <NavbarBrand tag={Link} to="/">Waterproofing</NavbarBrand>
                        </div>

                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                {
                                    localStorage.userAuthenticated ?
                                        <NavLink className="text-dark" style={{ cursor: 'pointer' }} onClick={this.handleLogOut}>Log Out</NavLink>
                                        : <NavLink tag={Link} className="text-dark" to="/login">Log In</NavLink>
                                }
                            </NavItem>
                        </ul>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
