import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { SideMenu } from './SideMenu';

export class Layout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenSidemenu: false
        }
    }
    toggleSidemenu = () => {
        this.setState({ isOpenSidemenu: !this.state.isOpenSidemenu });
    }

    render() {
        const noSidemenu = !localStorage.userAuthenticated ? 'no-sidemenu' : '';
        return (
            <div className="layout">
                <NavMenu toggleSidemenu={this.toggleSidemenu} />
                <Container className="d-flex gap-5 min-vh-100">
                    {
                        localStorage.userAuthenticated && <SideMenu isOpenSidemenu={this.state.isOpenSidemenu} />
                    }
                    <div className={`content w-100 overflow-auto ${noSidemenu}`}>
                        {this.props.children}
                    </div>
                </Container>
            </div>
        );
    }
}
