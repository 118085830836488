import React, { Component } from 'react';

export class DeleteMaterial extends Component {

    render() {
        return (
            <div className="ct-new">
                <div className="card">
                    <div className="card-header">Delete</div>
                    <div className="card-body">
                        <p>Do you confirm to delete this material?</p>
                        <div className="d-flex gap-4">
                            <button className="btn btn-secondary" onClick={this.props.saveDeleteMaterial}>Confirm</button>
                            <button className="btn btn-secondary" onClick={this.props.cancelDeleteMaterial}>Cancel</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}